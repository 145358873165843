const htmlTag = document.querySelector("html");
const bodyTag = document.querySelector("body");
const btnMobileNav = document.querySelector(".btn-mobile-menu");

btnMobileNav.addEventListener("click", () => {
  htmlTag.classList.toggle("mobile-nav-show");
  bodyTag.classList.toggle("mobile-nav-show");
});

const mySwiper = new Swiper(".swiper-container", {
  loop: true,
  speed: 400,
  autoplay: {
    delay: 10000,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
});
